import jsPDF from "jspdf";
import "jspdf-autotable";
import { notifySuccess, notifyError } from "./notifications";

const COLORS = {
  DEEP_NAVY: [0, 51, 102],
  CHARCOAL_GRAY: [80, 80, 80],
  MEDIUM_GRAY: [120, 120, 120],
  LIGHT_GRAY: [245, 245, 245],
};

const FONT = {
  FAMILY: "Helvetica",
  SIZES: {
    TITLE: 36,
    SUBTITLE: 14,
    BODY: 12,
    FOOTER: 10,
  },
};

const PAGE_LAYOUT = {
  MARGIN: 60,
  TITLE_Y: 70,
  METADATA_START_Y: 105,
  FOOTER_HEIGHT: 30,
};

export const generatePDF = (
  filteredStatistics,
  attendance,
  professor,
  semester,
  academicYear
) => {
  try {
    const doc = createDocument();
    const { pageWidth, pageHeight } = getPageDimensions(doc);
    
    addTitle(doc, pageWidth);
    addHeaderLine(doc, pageWidth);
    
    const metadataEndY = addMetadata(doc, {
      academicYear,
      semester,
      professor,
      totalLectures: calculateTotalLectures(filteredStatistics.length ? filteredStatistics : attendance),
    });
    
    addAttendanceTable(doc, filteredStatistics.length ? filteredStatistics : attendance, metadataEndY, pageHeight);
    
    saveDocument(doc, professor, semester);
    notifySuccess("Data downloaded as PDF file successfully.");
  } catch (error) {
    console.error("Error downloading PDF file: ", error);
    notifyError("Error downloading PDF file.");
  }
};

const createDocument = () => {
  return new jsPDF({
    orientation: "portrait",
    unit: "pt",
    format: "a4",
  });
};

const getPageDimensions = (doc) => ({
  pageWidth: doc.internal.pageSize.getWidth(),
  pageHeight: doc.internal.pageSize.getHeight(),
});

const addTitle = (doc, pageWidth) => {
  doc.setFont(FONT.FAMILY, "Normal");
  doc.setFontSize(FONT.SIZES.TITLE);
  doc.setTextColor(...COLORS.DEEP_NAVY);
  
  const title = "Attendance Report";
  const titleWidth = doc.getTextWidth(title);
  const titleX = (pageWidth - titleWidth) / 2;
  doc.text(title, titleX, PAGE_LAYOUT.TITLE_Y);
};

const addHeaderLine = (doc, pageWidth) => {
  doc.setDrawColor(...COLORS.DEEP_NAVY);
  doc.setLineWidth(1.5);
  doc.line(PAGE_LAYOUT.MARGIN, PAGE_LAYOUT.TITLE_Y + 15, pageWidth - PAGE_LAYOUT.MARGIN, PAGE_LAYOUT.TITLE_Y + 15);
};

const addMetadata = (doc, { academicYear, semester, professor, totalLectures }) => {
  const metadata = [
    { label: "Academic Year:", value: academicYear },
    { label: "Semester:", value: semester },
    { label: "Professor:", value: professor },
    { label: "Total Days:", value: totalLectures + " (on which attendance was recorded)" },
    { label: "Report Generated on:", value: new Date().toLocaleDateString() },
  ];

  doc.setFontSize(FONT.SIZES.SUBTITLE);
  doc.setTextColor(...COLORS.CHARCOAL_GRAY);

  let metadataY = PAGE_LAYOUT.METADATA_START_Y;
  const labelX = PAGE_LAYOUT.MARGIN;
  const valueX = PAGE_LAYOUT.MARGIN + 180;

  metadata.forEach(({ label, value }) => {
    doc.setFont(FONT.FAMILY, "Bold");
    doc.text(label, labelX, metadataY);

    doc.setFont(FONT.FAMILY, "Normal");
    doc.text(value.toString(), valueX, metadataY);

    metadataY += 25;
  });

  return metadataY;
};

const addAttendanceTable = (doc, data, startY, pageHeight) => {
  const headers = ["Roll Number", "Attended Lectures", "Attendance %"];
  const rows = data.map(({ rollNumber, attendedLectures }) => [
    rollNumber,
    attendedLectures,
    ((attendedLectures / calculateTotalLectures(data)) * 100).toFixed(2) + "%",
  ]);

  doc.autoTable({
    startY: startY + 20,
    head: [headers],
    body: rows,
    margin: { left: PAGE_LAYOUT.MARGIN, right: PAGE_LAYOUT.MARGIN, bottom: PAGE_LAYOUT.FOOTER_HEIGHT + 10 },
    styles: getTableStyles(),
    headStyles: getTableHeaderStyles(),
    alternateRowStyles: { fillColor: COLORS.LIGHT_GRAY },
    tableLineColor: [220, 220, 220],
    tableLineWidth: 0.75,
    theme: "striped",
    didDrawPage: (data) => addPageFooter(doc, data, pageHeight),
  });
};

const getTableStyles = () => ({
  font: FONT.FAMILY,
  fontSize: FONT.SIZES.BODY,
  cellPadding: 8,
  halign: "center",
  valign: "middle",
  lineColor: [220, 220, 220],
  lineWidth: 0.75,
  textColor: [50, 50, 50],
});

const getTableHeaderStyles = () => ({
  fillColor: COLORS.DEEP_NAVY,
  textColor: [255, 255, 255],
  fontStyle: "Bold",
  halign: "center",
  fontSize: FONT.SIZES.SUBTITLE,
});

const addPageFooter = (doc, data, pageHeight) => {
  const { pageWidth } = getPageDimensions(doc);
  const footerY = pageHeight - PAGE_LAYOUT.FOOTER_HEIGHT;

  doc.setDrawColor(220, 220, 220);
  doc.setLineWidth(0.75);
  doc.line(
    PAGE_LAYOUT.MARGIN,
    footerY,
    pageWidth - PAGE_LAYOUT.MARGIN,
    footerY
  );

  const pageCount = doc.internal.getNumberOfPages();
  const currentPage = doc.internal.getCurrentPageInfo().pageNumber;
  doc.setFontSize(FONT.SIZES.FOOTER);
  doc.setTextColor(...COLORS.MEDIUM_GRAY);
  doc.setFont(FONT.FAMILY, "Normal");
  const footerText = `Page ${currentPage} of ${pageCount}`;
  const textWidth = doc.getTextWidth(footerText);
  doc.text(
    footerText,
    (pageWidth - textWidth) / 2,
    footerY + 20
  );
};

const saveDocument = (doc, professor, semester) => {
  const currentDate = new Date().toISOString().split("T")[0];
  const sanitizedProfessor = professor.replace(/\s+/g, "_");
  const fileName = `attendance_report_${currentDate}_SEM_${semester}_${sanitizedProfessor}.pdf`;
  doc.save(fileName);
};

const calculateTotalLectures = (data) => {
  if (data.length === 0) return 0;
  return Math.max(...data.map((item) => item.attendedLectures));
};