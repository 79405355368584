import React, { useState } from 'react';
import { SEMESTERS, PROFESSORS, ACADEMIC_YEARS } from '../utils/constants';

function AttendanceForm({ handleAddAttendance, professor, setProfessor, semester, setSemester, academicYear, setAcademicYear }) {
  const [rollNumber, setRollNumber] = useState("");
  const [date, setDate] = useState(new Date().toISOString().split("T")[0]);

  const isFormValid = () => rollNumber && date && semester && professor && academicYear;

  const onSubmit = (e) => {
    e.preventDefault();
    if (isFormValid()) {
      handleAddAttendance({ rollNumber, date, semester, professor, academicYear });
      setRollNumber("");
    }
  };
  return (
    <section className="bg-gray-50 rounded-2xl p-8 shadow-lg">
      <h2 className="text-3xl sm:text-4xl font-bold mb-6 text-gray-800">Add Attendance</h2>
      <form className="space-y-6" onSubmit={onSubmit}>
        <input
          type="date"
          value={date}
          onChange={(e) => setDate(e.target.value)}
          className="w-full p-4 border border-gray-300 rounded-lg"
        />
        <select
          value={semester}
          onChange={(e) => setSemester(e.target.value)}
          className="w-full p-4 border border-gray-300 rounded-lg"
        >
          <option value="">Select Semester</option>
          {SEMESTERS.map((sem) => (
            <option key={sem} value={sem}>Semester {sem}</option>
          ))}
        </select>
        <select
          value={professor}
          onChange={(e) => setProfessor(e.target.value)}
          className="w-full p-4 border border-gray-300 rounded-lg"
        >
          <option value="">Select Professor</option>
          {PROFESSORS.map((prof) => (
            <option key={prof} value={prof}>{prof}</option>
          ))}
        </select>
        <select
          value={academicYear}
          onChange={(e) => setAcademicYear(e.target.value)}
          className="w-full p-4 border border-gray-300 rounded-lg"
        >
          {ACADEMIC_YEARS.map((year) => (
            <option key={year} value={year}>{year}</option>
          ))}
        </select>
   <input
  type="number"
  value={rollNumber}
  onChange={(e) => setRollNumber(e.target.value)}
  placeholder="Enter roll number"
  className="w-full p-4 border border-gray-300 rounded-lg"
/>
        <button
          type="submit"
          disabled={!isFormValid()}
          className="w-full mt-6 bg-gradient-to-r from-blue-500 to-purple-500 text-white p-4 rounded-lg hover:from-blue-600 hover:to-purple-600 transition duration-300 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed text-lg font-semibold"
        >
          Submit
        </button>
      </form>
    </section>
  );
}

export default AttendanceForm;