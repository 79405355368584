import React, { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";

import LoginForm from "./components/LoginForm";
import AttendanceForm from "./components/AttendanceForm";
import StatisticsForm from "./components/StatisticsForm";
import StatisticsTable from "./components/StatisticsTable";
import DownloadPDFButton from "./components/DownloadPDFButton";
import LoadingScreen from "./components/LoadingScreen";

import useAuth from "./hooks/useAuth";
import useAttendance from "./hooks/useAttendance";
import useStatistics from "./hooks/useStatistics";
import { ACADEMIC_YEARS } from './utils/constants';

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Show loading screen for 5 seconds
    const timer = setTimeout(() => {
      setLoading(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  const { user, handleLogin, handleLogout } = useAuth();
  const { attendance, handleAddAttendance } = useAttendance();
  const { 
    filteredStatistics, 
    showStatistics, 
    handleFetchStats, 
    dataFetched 
  } = useStatistics();

  // State variables for attendance form
  const [professor, setProfessor] = useState("");
  const [semester, setSemester] = useState("");
  const [academicYear, setAcademicYear] = useState(ACADEMIC_YEARS[0]);

  // State variables for statistics form
  const [filterProfessor, setFilterProfessor] = useState("");
  const [filterSemester, setFilterSemester] = useState("");
  const [filterAcademicYear, setFilterAcademicYear] = useState(ACADEMIC_YEARS[0]);

  // Determine values to pass to DownloadPDFButton
  const downloadProfessor = filterProfessor || professor;
  const downloadSemester = filterSemester || semester;
  const downloadAcademicYear = filterAcademicYear || academicYear;

  // Console logs for debugging
  console.log("Download Professor:", downloadProfessor);
  console.log("Download Semester:", downloadSemester);
  console.log("Download Academic Year:", downloadAcademicYear);

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <div className="min-h-screen bg-gradient-to-br from-blue-100 via-purple-100 to-pink-100 flex flex-col items-center p-4 sm:p-8">
          <ToastContainer position="top-center" autoClose={3000} />
          <div className="w-full max-w-full bg-white rounded-3xl shadow-2xl p-4 sm:p-8 md:p-12 space-y-10">
            <header className="text-center">
              <h1 className="text-5xl sm:text-7xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-purple-600 mb-4">
                CUSAC
              </h1>
              <p className="text-3xl sm:text-4xl font-semibold text-gray-700">
                Attendance Portal
              </p>
            </header>

            {!user ? (
              <LoginForm handleLogin={handleLogin} />
            ) : (
              <>
                <button
                  onClick={handleLogout}
                  className="bg-red-500 text-white p-2 rounded-lg hover:bg-red-600 transition duration-300 ease-in-out"
                >
                  Logout
                </button>

                <AttendanceForm
                  handleAddAttendance={handleAddAttendance}
                  professor={professor}
                  setProfessor={setProfessor}
                  semester={semester}
                  setSemester={setSemester}
                  academicYear={academicYear}
                  setAcademicYear={setAcademicYear}
                />

                <StatisticsForm
                  handleFetchStats={handleFetchStats}
                  filterProfessor={filterProfessor}
                  setFilterProfessor={setFilterProfessor}
                  filterSemester={filterSemester}
                  setFilterSemester={setFilterSemester}
                  filterAcademicYear={filterAcademicYear}
                  setFilterAcademicYear={setFilterAcademicYear}
                />

                {showStatistics && (
                  <StatisticsTable filteredStatistics={filteredStatistics} />
                )}

                <DownloadPDFButton 
                  dataFetched={dataFetched} 
                  filteredStatistics={filteredStatistics} 
                  attendance={attendance}
                  professor={downloadProfessor}
                  semester={downloadSemester}
                  academicYear={downloadAcademicYear}
                />
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default App;
